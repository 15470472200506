import FlashSale from "@/features/home/partials/FlashSale";

export enum PagePath {
  DashboardPage = '/',
  HomePage = '/',
  News = 'http://anthu.today/',
  LoginPage = '/login',
  LoginByPasswordPage = '/login-by-password',
  ForgotPasswordPage = '/forgot-password',
  ResetPasswordPage = '/forgot-password/[phone]/reset',
  SignUpPage = '/signup',
  LoginConfirmPage = 'login/[phone]/confirm',
  LiveVideosPage = '/truc-tiep',
  LiveVideoPage = '/truc-tiep/[id]',
  ProductsPage = '/san-pham',
  ProductDetailPage = '/san-pham/[id]',
  CategoryPage = '/danh-muc/[id]',
  CategoryProductPage = '/danh-muc/[id]/[slug]',
  ConsultChatPage = '/consults/[id]/chat',
  ConsultListChatPage = '/consults',
  ProductDepositPage = '/san-pham/[id]/deposit',
  AccountDepositPage = '/accounts/deposit',
  AccountProfilePage = '/accounts/profile',
  EditAccountProfilePage = '/accounts/profile/edit',
  AccountRatingPage = '/accounts/rating',
  UserLevelPage = '/accounts/user-level',
  OrderDetailPage = '/orders/[id]',
  RefundDetailPage = '/orders/refund-detail/[id]',
  BlogPage = '/blog/[id]',
  BlogMobilePage = '/blog-mobile/[id]',
  ArticlePage = '/article/[id]',
  ArticleMobilePage = '/article-mobile/[id]',
  OrderAfterServiceDetailPage = '/orders/after-service/[id]',
  OrderListPage = '/orders',
  DepositListPage = '/deposits',
  DepositDetailPage = '/deposits/[id]',
  SendGift = '/gifts/send',
  NotificationsPage = '/notifications',
  CheckoutPolicyPage = '/policies/checkout',
  PaymentGuidePolicyPage = '/policies/payment-guide',
  DepositPolicyPage = '/policies/deposit',
  CustomerBenefitPolicyPage = '/policies/customer-benefit',
  ProductWarrantyPolicyPage = '/policies/product-warranty',
  PrivacyPolicyPage = '/policies/privacy',
  CustomerLevelInfo = '/policies/customer-levels',
  ColorlessDiamondLevelInfo = '/policies/customer-levels/colorless-diamond',
  YellowDiamondLevelInfo = '/policies/customer-levels/yellow-diamond',
  PinkDiamondLevelInfo = '/policies/customer-levels/pink-diamond',
  BlueDiamondLevelInfo = '/policies/customer-levels/blue-diamond',
  RedDiamondLevelInfo = '/policies/customer-levels/red-diamond',
  LegalConsultationRegister = '/policies/customer-levels/legal-consultation',
  PrivacyPolicyPage1 = '/private-policy',
  Shops = '/shops',
  RefundPolicyPage = '/policies/refund',
  ConsignPolicyPage = '/policies/consign',
  RentalPolicyPage = '/policies/rent',
  DetailAtd = '/accounts/detail-atd',
  Introduce = '/accounts/referral',
  Rewards = '/accounts/rewards',
  AmbassadorInfo = '/accounts/ambassador-info',
  CommissionInfo = '/accounts/commission-info',
  RewardDetail = '/accounts/rewards/[id]',
  Referral = '/referral',
  ListReferral = '/accounts/referral/list',
  ShippingAndDeliveryPolicyPage = '/policies/shipping-and-delivery',
  DownloadPage = '/download',
  SiteMapPage = '/sitemap.xml',
  AboutPage = '/#',
  ProductHide = '/san-pham/hide',
  SupportPage = '/ho-tro',
  ReelPage = '/reels',
  Surveys = '/surveys',
  SurveyDetail = '/surveys/[id]',
  PartnerDetail = '/doi-tac/[id]',
  MeasuringGuidePage = 'guide-to-measuring-hands',
}

export const QueryKey = {
  Users: 'users',
  Orders: 'orders',
  OrderDetail: 'order_detail',
  Balance: 'balance',
  Streams: 'streams',
  Products: 'products',
  ProductChat: 'product_chat',
  Deposits: 'deposits',
  MasterData: 'master_data',
  LiveVideos: 'live_videos,',
  TrackingVideo: 'tracking_video',
  Conversations: 'conversations',
  Conversation: 'conversation',
  Transaction: 'transaction',
  Notifications: 'notifications',
  TotalByTab: 'total_by_tab',
  LiveVideoComments: 'live_video_comments',
  ConversationMessages: 'conversation_messages',
  ConversationUsers: 'conversation_users',
  Address: 'address',
  News: 'news',
  Sticker: 'sticker',
  ConversationPopup: 'conversation_popup',
  Referral: 'referral',
  RelatedProduct: 'related_product',
  Lucky: 'lucky',
  Popup: 'popup',
  Lottery: 'lottery',
  ListParticipants: 'list_participants',
  EventHistory: 'event_history',
  ReplyComment: 'reply_comment',
  Comment: 'comment',
  Logout: 'logout',
  GuaranteePaper: 'guarantee_paper',
  Blog: 'blog',
  Article: '',
  ListVoucher: 'list_voucher',
  Auction: 'auction',
  ProductCategory: 'product_category',
  Categories: 'categories',
  RatingStaff: 'rating_staff',
  GetRatings: 'get_rating',
  Reel: 'reel',
  ReelComment: 'reel_comment',
  ReelReplyComment: 'reel_reply_comment',
  ReelTracking: 'reel_tracking',
  Ambassador: 'ambassador',
  Commission: 'commission',
  UserLevel: 'user/level',
  PartnersForBanner: 'partner/banners',
  FlashSale: 'flash_sale',
};

export enum PriceSymbol {
  '$' = '$',
  'Atd' = 'atd',
  'Million' = 'million',
}
export enum KeyActive {
  Home = 'home',
  News = 'news',
  Product = 'product',
  LiveVideo = 'live-video',
  Account = 'account',
  ProductDetail = 'product-detail',
  GuideMeasuring = 'guid-measuring',
  Reels = 'reels',
}
export enum PaymentMethod {
  Atd = 'ATD',
  Transfer = 'TRANSFER',
  Cash = 'CASH',
  SwipeCard = 'SWIPECARD',
}

export enum OrderType {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Exchange = 'EXCHANGE',
  Return = 'RETURN',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Processing = 'PROCESSING',
  Delivering = 'DELIVERING',
  Debt = 'DEBT',
}

export enum CancelType {
  CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
  CancelledByDraftOrder = 'CANCELLED_BY_DRAFT_ORDER',
  CancelledByNotDelivery = 'CANCELLED_BY_NOT_DELIVERY',
}
export enum DepositType {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Failed = 'FAILED',
}

export enum TransactionType {
  Atd = 'ATD',
  TopUpAtd = 'TOP_UP_ATD',
  DepositOrder = 'DEPOSIT_ORDER',
  Payoff = 'PAY_OFF',
  PayOffOrder = 'PAY_OFF_ORDER',
  GiftAtd = 'GIFT_ATD',
  DebitOrder = 'DEBT_ORDER',
  PayOffDebitOrder = 'PAY_OFF_DEBT_ORDER',
  ExchangeOrder = 'EXCHANGE_ORDER',
  ReturnOrder = 'RETURN_ORDER',
  RefundOrder = 'REFUND_ORDER',
  BonusReferralUser = 'BONUS_REFERRAL_USER',
  BonusIntroducedUser = 'BONUS_INTRODUCED_USER',
  SuccessSignUp = 'SUCCESS_SIGN_UP',
  PayOffAsOrder = 'PAY_OFF_AS_ORDER',
  OpenLuckyAtdBox = 'OPEN_LUCKY_ATD_BOX',
  Deduction = 'DEDUCTION',
  DeductionOrder = 'DEDUCTION_ORDER',
  GiftAtdLive = 'LIVE_STREAM_GIFT_ATD',
}

export enum PageLayout {
  Basic = 'basic',
  Private = 'private',
  Mix = 'mix',
  New = 'new',
}

export enum NotificationType {
  Order = 'ORDER',
  AfterServiceOrder = 'AFTER_SERVICE_ORDER',
  Livestream = 'LIVESTREAM',
  Gift = 'GIFT',
  OrderService = 'ORDER_SERVICE',
  BonusIntroducedUser = 'BONUS_INTRODUCED_USER',
  BonusReferralUser = 'BONUS_REFERRAL_USER',
  ReplyComment = 'REPLY_COMMENT',
  VoucherExpireSoon = 'VOUCHER_EXPIRE_SOON',
  ManuallyVoucher = 'MANUALLY_VOUCHER',
  RemindResellProduct = 'REMIND_RESELL_PRODUCT',
  AuctionWin = 'AUCTION_WIN',
  RentalOrderCreated = 'RENTAL_ORDER_CREATED',
  RentalOrderConfirmed = 'RENTAL_ORDER_CONFIRMED',
  RentalOrderProcessing = 'RENTAL_ORDER_PROCESSING',
  RentalOrderRenting = 'RENTAL_ORDER_RENTING',
  RentalOrderPaymentPending = 'RENTAL_ORDER_PAYMENT_PENDING',
  RentalOrderCompleted = 'RENTAL_ORDER_COMPLETED',
  RentalOrderCancelled = 'RENTAL_ORDER_CANCELLED',
  Chat = 'CHAT',
}

export enum SocialType {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  AppleStore = 'AppleStore',
  GoogleStore = 'GoogleSotre',
  Certificate = 'Certificate',
  Youtube = 'Youtube',
  TikTok = 'TikTok',
}

export enum BctType {
  Announced = 'Announced',
}
export enum MessageType {
  Text = 'text',
  OrderInfo = 'order_info',
  SellerAccepted = 'seller_accepted',
  Product = 'product',
  Direct = 'direct',
  LiveStream = 'livestream',
  Marketplace = 'marketplace',
  Video = 'video',
  Sticker = 'sticker',
  NewMessage = 'new_message',
  SeenMessage = 'new_message',
  Handover = 'handover',
  AuctionWin = 'auction_win',
  CreatedGroup = 'created_group',
  InvitedUser = 'invited_user',
}
export enum MessageMethodType {
  NewMessage = 'new_message',
  SeenMessage = 'seen_message',
  CreateComment = 'create_comment',
  JoinLivestream = 'user_join_livestream',
  Sticker = 'sticker',
  GiftAtd = 'live_stream_gift_atd',
}
export enum NotificationSocketType {
  CreateReplyComment = 'create_reply_comment',
  ReadAllNotification = 'read_all_notification',
  ReadNotification = 'read_notification',
}
export enum EventType {
  Wishes = 'WISHES',
  Gift = 'GIFT',
  Voucher = 'VOUCHER',
  Atd = 'ATD',
  Lottery = 'LOTTERY',
}
export enum LuckyStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
}
export enum ProductStatus {
  Availability = 'AVAILABILITY',
  EnterpriseSuspension = 'ENTERPRISE_SUSPENSION',
  CloseASale = 'CLOSE_A_SALE',
  Deposited = 'DEPOSITED',
  Hide = 'HIDE',
}
export enum DiscountStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Used = 'USED',
  Ended = 'ENDED',
}

export enum AccountStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Active = 'ACTIVE',
  Deactivate = 'DEACTIVATE',
}

export enum City {
  HN = 'HN',
  HCM = 'HCM',
}
export enum CityName {
  HN = 'Hà Nội',
  HCM = 'Hồ Chí Minh',
}
export enum DefaultCountry {
  Vn = 'VN',
}
export enum TransactionStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
  Cancelled = 'CANCELLED',
  Refunded = 'REFUNDED',
  Failed = 'FAILED',
  BonusReferralUser = 'BONUS_REFERRAL_USER',
  BonusIntroducedUser = 'BONUS_INTRODUCED_USER',
  SuccessSignUp = 'SUCCESS_SIGN_UP',
}

export enum CommentMethod {
  Sticker = 'sticker',
  CreateComment = 'create_comment',
  UpdateComment = 'update_comment',
  DeleteComment = 'delete_comment',
  CreateReplyComment = 'create_reply_comment',
  DeleteReplyComment = 'delete_reply_comment',
  UpdateReplyComment = 'update_reply_comment',
  HideReplyComment = 'hide_reply_comment',
  UnHideReplyComment = 'unhide_reply_comment',
  HideComment = 'hide_comment',
  UnHideComment = 'unhide_comment',
  FinishLucky = 'finish_lucky',
  ProcessingLucky = 'processing_lucky',
  OpenLuckyBox = 'open_lucky_box',
}
export enum AfterServiceStatus {
  Editing = 'EDITING',
  Processing = 'PROCESSING',
  Delivering = 'DELIVERING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum TabOrderName {
  PurchaseOrder = 'PurchaseOrder',
  PurchasedApplication = 'PurchasedApplication',
}
export enum CurrencySymbol {
  Vnd = 'VND',
  Atd = 'ATD',
}

export enum ReferralStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Pending = 'PENDING',
}
export enum Unit {
  Percentage = 'PERCENTAGE',
  Currency = 'CURRENCY',
}
export enum LotteryGameStatus {
  Pending = 'PENDING',
  Starting = 'STARTING',
  Ended = 'ENDED',
}
export enum LotteryTicketStatus {
  Pending = 'PENDING',
  CloseList = 'CLOSE_LIST',
  Win = 'WIN',
  Lose = 'LOSE',
}
export enum LotterySocketMethod {
  OpenLottery = 'open_lottery',
  CloseListLottery = 'close_list_lottery',
  UserConfirmTicket = 'user_confirm_ticket_lottery',
  EndLottery = 'end_lottery',
  AdminPickTicketLottery = 'admin_pick_ticket_lottery',
}

export enum LiveProductMethod {
  PinProduct = 'pin_product',
  CreateOrder = 'create_order',
  AuctionStarted = 'auction_started',
  AuctionEnded = 'auction_ended',
  AuctionOffer = 'auction_offer',
}

export enum ReelMethod {
  CreateComment = 'create_comment',
  UpdateComment = 'update_comment',
  DeleteComment = 'delete_comment',
  HideComment = 'hide_comment',
  UnHideComment = 'unhide_comment',
  CreateRelyComment = 'create_reply_comment',
  DeleteRelyComment = 'delete_reply_comment',
  UpdateRelyComment = 'update_reply_comment',
  HideReplyComment = 'hide_reply_comment',
  UnHideReplyComment = 'unhide_reply_comment',
}

export enum SocketChanel {
  LiveStream = 'atd_livestream',
  Consult = 'atd_chat',
  AtdNotification = 'atd_notification',
  Reel = 'atd_reel',
}

export enum EventHistory {
  Atd = 'ATD',
  Wishes = 'WISHES',
  Gift = 'GIFT',
  Lottery = 'LOTTERY',
  Voucher = 'VOUCHER',
}

export enum LiveStreamStatus {
  LiveNow = 'LIVE_NOW',
  Unpublished = 'UNPUBLISHED',
  ScheduledLive = 'SCHEDULED_LIVE',
  ScheduledCancel = 'SCHEDULED_CANCELED',
  ScheduledUnpublished = 'SCHEDULED_UNPUBLISHED',
  Vod = 'VOD',
  Live = 'LIVE',
}
export enum NewsLayout {
  EmagazineLayout = 'emagazine-layout',
  PromotionsLayout = 'promotions-layout',
  JewelryLayout = 'jewelry-layout',
}

export enum CancelAmountType {
  PaidOff = 'PAID_OFF',
  Deposited = 'DEPOSITED',
}
export enum PlatForm {
  Mobile = 'mobile',
  Web = 'web',
}
export enum AuctionStatus {
  Pending = 'PENDING',
  Started = 'STARTED',
  Ended = 'ENDED',
}

export enum UserAuctionStatus {
  Win = 'WIN',
  Lose = 'LOSE',
  Pending = 'PENDING',
}

export enum VoucherType {
  PUBLIC = 'PUBLIC',
  LIMITED = 'LIMITED',
  MANUALLY = 'MANUALLY',
  AMBASSADOR_NORMAL = 'AMBASSADOR_NORMAL',
  AMBASSADOR_UNLIMITED = 'AMBASSADOR_UNLIMITED',
}

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum AmbassadorOrderCommissionStatus {
  PENDING = 'PENDING', // Mới tạo
  UNPAID = 'UNPAID', // Chờ thanh toán
  PAID = 'PAID', // Đã thanh toán
  CANCELLED = 'CANCELLED' // Đã huỷ
}

export enum UserLevel {
  MEMBER = 'MEMBER',
  BASIC = 'BASIC',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  VIP = 'VIP',
}

export const UserLevelInfoPageMap: {[key: string]: string} = {
  [UserLevel.BASIC]: PagePath.ColorlessDiamondLevelInfo,
  [UserLevel.SILVER]: PagePath.YellowDiamondLevelInfo,
  [UserLevel.GOLD]: PagePath.PinkDiamondLevelInfo,
  [UserLevel.PLATINUM]: PagePath.BlueDiamondLevelInfo,
  [UserLevel.VIP]: PagePath.RedDiamondLevelInfo,
}